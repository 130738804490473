<template>
  <div
    class="card"
  >
    <div
      v-b-toggle="`collapseFullReference`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Type of publication</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseFullReference`"
      visible
    >
      <div class="card-content ">
        <div class="card-body">
          <div class="row">
            <div
              v-for="publiType in types"
              :key="publiType.id"
              class="col-md-4 mb-1"
            >
              <div class="form-check form-check-inline">
                <input
                  :id="'publiType-' + publiType.id"
                  v-model="publication.publication_type"
                  class="form-check-input"
                  type="radio"
                  name="publicationType"
                  :value="publiType"
                  @click="getReference()"
                >
                <label
                  class="form-check-label"
                  :for="'publiType-' + publiType.id"
                >{{ publiType.name }}</label>
              </div>
            </div>
          </div>
          <hr v-if="!publication.id">
          <div
            v-if="!publication.id"
            class="row"
          >
            <div
              v-if="publication.publication_type.id"
              class="ms-1 col-2 form-check form-check-inline"
            >
              <input
                id="publitype2"
                class="form-check-input"
                type="radio"
                name="publitype"
                :checked="fillType === 'paste'"
                @click="fillType = 'paste'"
              >
              <label
                class="form-check-label"
                for="publitype2"
              > Full reference copy-paste</label>
            </div>
            <div
              v-if="publication.publication_type && publication.publication_type.name == 'Article in a Journal'"
              class="col-2 ms-1 form-check form-check-inline"
            >
              <input
                id="publitype1"
                class="form-check-input"
                type="radio"
                name="publitype"
                :checked="fillType === 'manually'"
                @click="fillType = 'manually'"
              >
              <label
                class="form-check-label"
                for="publitype1"
              > Manually filled-in fields</label>
            </div>
          </div>
          <hr v-if="publication.publication_type.id">
          <div class="row">
            <div
              v-if="publication.publication_type && publication.publication_type.id && fillType == 'manually' && !publication.id"
              class="col-6"
            >
              <h4 class="mb-1">
                Publication full reference
              </h4>
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Authors</label>
                    <textarea
                      v-model="publication.authors"
                      class="form-control"
                    />
                    <p><small>e.g. Fernandez-Alrafa M, Sivilio A, Martín J, Uxo T & Mendez R</small></p>
                  </div>
                  <div
                    v-if="publication.publication_type && publication.publication_type.show_all_full_reference"
                    class="mb-1"
                  >
                    <label
                      for=""
                      class="form-label"
                    >Title of the ({{ publication.publication_type.name }})</label>
                    <quill-editor
                      v-model="publication.title"
                      @blur="getReference"
                    />
                  </div>
                </div>
              </div>

              <hr>

              <div class="row mt-2">
                <div class="col-sm-12">
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >{{ publication.publication_type.label_name ? publication.publication_type.label_name : `(${publication.publication_type.name}) title` }}</label>
                    <input
                      v-model="publication.title"
                      type="text"
                      class="form-control"
                      @change="getReference"
                    >
                  </div>
                </div>
                <div
                  v-if="publication.publication_type && publication.publication_type.name == 'Article in a Journal'"
                  class="col-sm-6"
                >
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Journal</label>
                    <input
                      v-model="publication.publication_name"
                      type="text"
                      class="form-control"
                      aria-describedby="linkToWeb"
                      @change="getReference"
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Reference (No. Ser. Vol. Pages)</label>
                    <input
                      v-model="publication.noservol_pages"
                      type="text"
                      class="form-control"
                      @change="getReference"
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-10">
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Link to the publication (DOI)</label>
                    <div class="input-group">
                      <input
                        v-model="publication.link_publication"
                        type="text"
                        class="form-control"
                        aria-describedby="linkToWeb"
                        @change="getReference"
                      >
                      <a
                        id="linkToWeb"
                        :href="publication.link_publication"
                        class="btn btn-outline-primary"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Go to link"
                        target="_blank"
                      ><i data-feather="external-link" /></a>
                    </div>
                    <p><small>Remember to put the complete URL. Example: http://dx.doi.org/.../...</small></p>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Year</label>
                    <date-picker
                      v-model="publication.year"
                      format="Y"
                      type="year"
                      value-type="format"
                      class="w-100"
                      @change="getReference"
                    />
                  </div>
                </div>
              </div>

              <hr>

              <div class="row">
                <div class="col-md-5">
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Status</label>
                    <v-select
                      v-model="publication.publication_status"
                      label="name"
                      :options="statuses"
                      :get-option-key="option => option.id"
                      @search="onSearch({ name: $event }, 'publicationStatus/filter')"
                    />
                  </div>
                </div>
                <div
                  v-if="(loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('publication-admin')) || (publication.publication_stage && publication.publication_stage.id)"
                  class="col-md-7"
                >
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Stage</label>
                    <v-select
                      v-model="publication.publication_stage"
                      label="name"
                      :disabled="!loggedUser.roles.includes('super-admin') || !loggedUser.roles.includes('publication-admin')"
                      :options="stages"
                      :get-option-key="option => option.id"
                      @search="onSearch({ name: $event }, 'publicationStages/filter')"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="publication.publication_type && publication.publication_type.id && (fillType == 'paste' || publication.id)"
              class="col-6"
            >
              <h4>Publication full reference</h4>
              <div class="row">
                <div class="col-12">
                  <p>
                    Reference examples, you are Smith J:
                  </p>
                  <p class="mb-0">
                    <u>Paper in Journal</u>: <strong>Smith J</strong>, Sauer P & Wagner LW 2015, ‘Effects of child care in ......’, <em>Journal of Youth Studies</em>, vol. 5, no. 2, pp 42-48.
                  </p>
                  <p>
                    <u>Book</u>: <strong>Smith J</strong> & Doe J 2015, <em>Thermodynamics: an engineering approach</em>, 2nd edn, McGraw Hill, London.
                  </p>
                  <div class="mb-1">
                    <label
                      class="form-label"
                    >Content of publication - full reference</label>
                    <quill-editor
                      v-model="fullReference"
                    />
                  </div>
                </div>
                <div
                  v-if="publication.publication_type && publication.publication_type.name == 'Article in a Journal'"
                  class="col-sm-8"
                >
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Journal (Please write again the name of the journal)</label>
                    <input
                      v-model="publication.publication_name"
                      type="text"
                      class="form-control"
                      aria-describedby="linkToWeb"
                      @change="getReference"
                    >
                  </div>
                </div>
                <div
                  class="col-md-4"
                >
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Year (Please write again the year)</label>
                    <date-picker
                      v-model="publication.year"
                      format="Y"
                      type="year"
                      value-type="format"
                      class="w-100"
                      @change="getReference"
                    />
                  </div>
                </div>
                <div
                  class="col-sm-12"
                >
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Link to the publication (DOI)</label>
                    <div class="input-group">
                      <input
                        v-model="publication.link_publication"
                        type="text"
                        class="form-control"
                        aria-describedby="linkToWeb"
                        @change="getReference"
                      >
                      <a
                        id="linkToWeb"
                        :href="publication.link_publication"
                        class="btn btn-outline-primary"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Go to link"
                        target="_blank"
                      ><i data-feather="external-link" /></a>
                    </div>
                    <p><small>Remember to put the complete URL. Example: http://dx.doi.org/.../...</small></p>
                  </div>
                </div>
                <hr>
                <div
                  class="col-md-5"
                >
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Status</label>
                    <v-select
                      v-model="publication.publication_status"
                      label="name"
                      :options="statuses"
                      :get-option-key="option => option.id"
                      @search="onSearch({ name: $event }, 'publicationStatus/filter')"
                    />
                  </div>
                </div>
                <div
                  v-if="(loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('publication-admin')) || (publication.publication_stage && publication.publication_stage.id)"
                  class="col-md-7"
                >
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Stage</label>
                    <v-select
                      v-model="publication.publication_stage"
                      label="name"
                      :disabled="!loggedUser.roles.includes('super-admin') && !loggedUser.roles.includes('publication-admin')"
                      :options="stages"
                      :get-option-key="option => option.id"
                      @search="onSearch({ name: $event }, 'publicationStages/filter')"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="form-check form-check-success form-switch d-flex align-items-center">
                  <input
                    v-model="publication.publish"
                    type="checkbox"
                    class="form-check-input"
                  >
                  <label
                    class="form-check-label"
                    for=""
                  ><small class="ms-1">Publish in web </small></label>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div></b-collapse>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    BCollapse,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      fullReference: '',
      fillType: null,
    }
  },
  computed: {
    ...mapGetters({
      publication: 'publications/item',
      statuses: 'publicationStatus/items',
      stages: 'publicationStages/items',
      types: 'publicationTypes/items',
      loggedUser: 'auth/admin',
    }),
    type() {
      return this.publication.publication_type
    },
  },
  watch: {
    type(type, old) {
      if (old && old.id !== this.type.id) {
        this.fillType = 'paste'
      }
    },
    fullReference() {
      this.publication.full_reference = this.fullReference
    },
    fillType(type) {
      this.$emit('setFillType', type)
    },
  },
  async mounted() {
    await this.$store.dispatch('publicationTypes/fetch')
    await this.$store.dispatch('publicationStatus/fetch')
    await this.$store.dispatch('publicationStages/fetch')

    /* if (this.publication.publication_stage.length === 0) {
      this.publication.publication_stage = this.stages.find(el => el.default === true)
    } */
    this.fullReference = `${this.publication.full_reference ? this.publication.full_reference : ''}`
    this.setReference()
  },
  methods: {
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    setReference() {
      this.publication.full_reference = this.fullReference
    },
    getReference() {
      if (this.publication.id) {
        setTimeout(() => {
          let link = ''
          if (this.publication.publication_type && !this.publication.publication_type.show_all_full_reference) {
            link = `'<em><a href="${this.publication.link_publication}">${this.publication.publication_name}</a></em>'`
          } else {
            link = `'<a href="${this.publication.link_publication}">${this.publication.title}</a>', <em>${this.publication.publication_name}</em>`
          }

          this.fullReference = `${this.publication.authors.replaceAll(',', '').replaceAll(';', ',')}, ${this.publication.year} ${link}, ${this.publication.noservol_pages ?? (this.publication.wos_data && this.publication.wos_data.noservol_pages ? this.publication.wos_data.noservol_pages : '')}`
        }, 100)
      }
    },
  },
}
</script>
