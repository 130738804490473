<template>
  <div
    v-if="publication.wos_data"
    class="card"
  >
    <div
      v-b-toggle="`collapseWosData`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">WOS imported</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseWosData`"
    >
      <div class="card-content ">
        <div class="card-body">
          <p>This block displays the information imported from WOS. <a
            href="#"
            target="_blank"
          >Download complete excel</a></p>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >WOS ID</label>
                <input
                  v-model="publication.wos_data.wos_id"
                  type="text"
                  class="form-control"
                  disabled
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >DOI</label>
                <div class="input-group">
                  <input
                    v-model="publication.wos_data.wos_id"
                    type="text"
                    class="form-control"
                    aria-describedby="linkToWeb"
                    disabled
                  >
                  <a
                    id="linkToWeb"
                    :href="publication.wos_data.doi"
                    class="btn btn-outline-primary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Go to link"
                    target="_blank"
                  ><i data-feather="external-link" /></a>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Title</label>
                <input
                  v-model="publication.wos_data.title"
                  type="text"
                  class="form-control"
                  disabled
                >
              </div>
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Authors</label>
                <quill-editor
                  v-model="publication.wos_data.authors"
                  disabled
                />
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-2">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Type of publication</label>
                <input
                  v-model="publication.wos_data.publication_type"
                  type="text"
                  class="form-control"
                  disabled
                >
              </div>
            </div>
            <div class="col-md-7">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Publication name</label>
                <input
                  v-model="publication.wos_data.publication_name"
                  type="text"
                  class="form-control"
                  disabled
                >
              </div>
            </div>
            <div class="col-md-1">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Year</label>
                <input
                  v-model="publication.wos_data.year"
                  type="text"
                  class="form-control"
                  disabled
                >
              </div>
            </div>
            <div class="col-md-2">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >NoSerVolPages</label>
                <input
                  v-model="publication.wos_data.noservol_pages"
                  type="text"
                  class="form-control"
                  disabled
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCollapse,
  },
  computed: {
    ...mapGetters({
      publication: 'publications/item',
    }),
  },
}
</script>
